import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.headers);
      return Promise.reject(error.response.data || "Something went wrong");
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
      return Promise.reject(error.request || "Something went wrong");
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log("Error", error.message);
      return Promise.reject(error.message || "Something went wrong");
    }
  }
);

export default axiosInstance;
