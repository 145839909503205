import { NewLoginScreenFooterLogoIcon } from "../../assets"
import "./appFooter.css"

const Footer = () => {
    return (
        <div className='login-form-footer'>
            <span className='login-form-footer-content'>
                This application is protected by copyright law and international treaties. <br/>
                © 2024 Schneider Electric Industries SAS. All Rights Reserved.
            </span>
            <div>
                <NewLoginScreenFooterLogoIcon style={{width:"14.5vw"}}/>
            </div>
        </div>
    )
}

export default Footer