import React from "react";
import logo_zenatix from "../../assets/images/logo_zenatix.png";
import logo_zenatix_black from "../../assets/images/logo_zenatix_black.png";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Header.css";

const Header = () => {

  const [searchParams] = useSearchParams();
  const [isShowPrimaryLogo, setIsShowPrimaryLogo] = useState(true)

  useEffect(() => {
    setSource();
  }, [searchParams.get("source")]);

  const setSource = () => {
    if (searchParams.get("source")) {
      setIsShowPrimaryLogo(false)
    }
  }

  return (
    <div className="header-wrapper">
      <img src={isShowPrimaryLogo ? logo_zenatix : logo_zenatix_black} alt={"zenatix-logo"} width={180} />
      <hr />
    </div>
  );
};

export default Header;
