import React, { useEffect, useState } from "react";
import { Button, Typography, Modal, Space } from "antd";
import { OptOutDownloadIcon } from "../../assets";
import DOMPurify from "dompurify";
import "./ResetPassword.css";
import axios from "axios";

const { Text, Title } = Typography;

const TermsConditionModal = ({
  termsData,
  modalOpen,
  setModalOpen,
  setTermsAccepted,
  setTermsError,
  SETheme,
  username,
  isMobileView
}) => {
  const { title, description, text, is_active } = termsData;
  const [termsViewed, setTermsViewed] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        scrollModalContentToTop();
        onModalContentScroll();
      }, 0);
    }
  }, [modalOpen]);

  const scrollModalContentToTop = () => {
    const modalContent = document.getElementById("terms-content");
    if (modalContent) {
      modalContent.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const onModalContentScroll = () => {
    let ele = document.getElementById("terms-content");
    let value = ele.scrollHeight - Math.ceil(ele.scrollTop);
    // handling +- 1px differences or variations while scrolling
    if (value - 1 <= ele.clientHeight && ele.clientHeight <= value + 1) {
      setTermsViewed(true);
    } else {
      setTermsViewed(false);
    }
  };

  const handleTNCDownload = async () => {
    try {
      const response = await axios.post('/users/downloadTerms&Conditions', {data: {username}}, {responseType: 'blob'})
      let a = document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      a.download = `Privacy Policy.pdf`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      return true;
    } catch (error) {
      console.log({error});
      // toast.error(error?.response?.data?.resp || ("We Encountered an error, please try sometime later..."), {icon: toastError()})
    }
  }

  const modalFooter = [
    <div style={{ margin: '0 5px 0 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      className={isMobileView && "tnc-cma-footer-btns"}>
      <div onClick={handleTNCDownload} className="download-tnc">
        <OptOutDownloadIcon /> Download
      </div>
      <div>
        <Button
          key="submit"
          type="primary"
          disabled={!termsViewed}
          className="form-btn accept-modal-btn"
          onClick={() => {
            setTermsAccepted(true);
            setModalOpen(false);
            setTermsError(false);
          }}
        >
          Agree
        </Button>
        <Button
          key="back"
          className="form-btn cancel-modal-btn"
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  ];

  // return a DOM HTMLBodyElement instead of an HTML string to prevent mutation XSS
  const sanitizedDOM = DOMPurify.sanitize(text, { RETURN_DOM: true });

  return (
    <div className="TermsConditionModal-wrapper">
      <Modal
        centered
        closable={false}
        open={modalOpen}
        width={600}
        className={SETheme + ' ' + `terms-and-conditions-modal ${isMobileView && " changes-tnc-modal-cma"}`}
        footer={modalFooter}
        style={{zIndex: '99999'}}
      >
        <Space direction={"vertical"}>
          {is_active ? (
            <React.Fragment>
              <Title level={3} className="terms-modal-title">
                {title}
              </Title>
              <Title level={4} className="terms-modal-description">
                {description}
              </Title>
              <Text
                id="terms-content"
                color="#707070"
                className="terms-modal-text"
                onScroll={(e) => onModalContentScroll()}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizedDOM ? sanitizedDOM.outerHTML : "",
                  }}
                />
              </Text>
            </React.Fragment>
          ) : (
            <Title level={3} className="terms-modal-title">
              {"No record found !!"}
            </Title>
          )}
        </Space>
      </Modal>
    </div>
  );
};

export default TermsConditionModal;
