
import "./verifyOTPNewUser.css"
import React, { useState, useEffect } from 'react';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { Select, Modal, Space, Typography  } from 'antd';
import axios from "../../api";
import logo_se from "../../assets/images/logo-se.png"
import { DownArrowFilledIcon, DownArrowIcon, NewLoginScreenFooterLogoIcon, OTPVerificationIcon, OTPVerificationSEIcon } from "../../assets";

const { Text, Link, Title } = Typography;

const NewVerifyOTPNewUser = ({formValues, newUserInfo, termsData, termsAccepted}) => {

    const [enableOTP, setEnableOTP] = useState({});
    const [otpSource, setOTPSource] = useState("Email")
    const [showTimer, setShowTimer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [timer, setTimer] = useState(60);
    const [OTP, setOTP] = useState('')
    const [disableButton, setDisableButton] = useState(false)
    const { id } = termsData || {};
    
    useEffect(() => {
        let intervalId;
        if (showTimer && timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer == 0) {
            setDisableButton(false)
            setShowTimer(false);
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [showTimer, timer]);

    const handleChange = (value) => {
        setOTPSource(value)
    }

    const sendOTP = async () => {
        try{
            setDisableButton(true)
            let payload = {
                username: formValues.username
            }
            if(otpSource === "Email")
                payload = {...payload, isEmailVerified: false}
            else
                payload = {...payload, isPhoneVerified: false}
            const response = await axios.post("/users/generateVerifyOtp", {...payload});
            if (response.status === 200 && response.data.otp_sent){
                setEnableOTP({success: 'Code sent successfully'});
                setTimer(60); 
                setShowTimer(true)
            }
            else{
                setEnableOTP({
                    error: `Unable to generate code.`
                });
                setDisableButton(false)
            }
        } catch(e){
            setDisableButton(false)
            console.log(e);
            setEnableOTP({
                error: e?.message || `Unable to generate code.`
            });
        }
    };

    const handleOTPInput = (e) => {
        if(e.target.value.length < 7){
            setOTP(e.target.value)
        }
    }

    const onFinishOTP = async () => {
        try{
            let payload = {
                username: formValues.username,
                password: formValues.newPassword,
                is_accepted: termsAccepted,
                terms_and_conditions: id && id.toString()
            };
            if(otpSource === "Email")
                payload = {...payload, email_otp: OTP}
            else
                payload = {...payload, phone_otp: OTP}
            // return
            const response = await axios.post("/users/validatePasswordReset", {...payload});
            if (response.status === 200 && response.data.password_reset) {
                setShowModal(true)
            } 
        } catch (error) {
            if (error && error.error && error.error.message) {
                // setErrorMsg(error.error.message);
                setEnableOTP({
                    error: error.error.message
                });
            } else if(error && error.message) {
                // setErrorMsg(error.message);
                setEnableOTP({
                    error: error.message || `Invalid code, please try again.`
                });
            }
        }
    };

    const getOTPFormFields = () => {
        return <>
            <div className='new-otp-container'>
            {
                enableOTP.error && <Message negative className="new-negative-message">
                    <Message.Header>{enableOTP.error}</Message.Header>
                </Message>
            }
            {
                enableOTP.success && <Message success className="new-negative-message new-success-message">
                    <Message.Header>{enableOTP.success}</Message.Header>
                </Message>
            }
                <div className='new-otp-source-container'>
                    <Select
                        suffixIcon={<DownArrowFilledIcon className="dropdown-arrow-icon"/>}
                        defaultValue={otpSource}
                        className='new-otp-source-dropdown'
                        onChange={handleChange}
                        options={newUserInfo["isPhoneVerified"] ? [
                            {
                                value: 'Email',
                                label: 'Email',
                            },
                            {
                                value: 'Phone',
                                label: 'Phone',
                            },
                        ]: [{
                            value: 'Email',
                            label: 'Email',
                        }]}
                    />
                    <Button id={'new-otp-source-generate-btn'} onClick={sendOTP} style={disableButton ? {opacity: '0.4', pointerEvents: 'none'} : {opacity: '1'}}>
                        Send Code
                    </Button>
                </div>
                <div>
                    <Form.Input fluid iconPosition='left' placeholder='Enter Verification Code' id="new-OTP-input"
                        name="OTP" value={OTP} onChange={handleOTPInput} />
                </div>
                <div className="resend-otp-new-user-page">
                    { showTimer && <span>If you didn't receive the verification code,  <span className={"additional-trigger-timer-count"}>retry in {timer} sec.</span> </span>}
                </div>
                <div>
                    <Button className={(OTP && OTP.length === 6) ? 'new-submit-login' : 'new-submit-login disable-submit-login'} type="primary" htmlType="submit" onClick={onFinishOTP}>
                        Submit
                    </Button>
                </div>
            </div>
        </>
    }

    return(
        <div className='new-login-form'>
            <div className='login-infomation'>
                <div className='green-color-cover-container'>
                    <div className='transparent-green-cover'></div>
                    <div className='green-cover'></div>
                </div>
                <div className='login-logo-instruction-container'>
                    <div>
                        <img src={logo_se} style={{height: "36lvh",marginTop:"-12.5lvh",marginLeft:"-4lvw"}}/>
                    </div>
                    <div className={"new-login-form-container"}>
                        <div className={"new-login-instruction cs-title"}>
                            Verify your Identity
                        </div>
                        {getOTPFormFields()}
                    </div>
                </div>
            </div>
            <Modal
                centered={true}
                footer={null}
                closable={false}
                open={showModal}
                className="otp-section-modal"
                width={380}
            >
                <Space direction={"vertical"} className="reset-modal-space">
                    <Title level={3} className="reset-modal-title">
                        Your password has been reset!
                    </Title>
                    <Text color="#707070" className="reset-modal-sub-title">
                        {
                            "To ensure your account's security, you will need to log in to the dashboard with your updated credentials."
                        }
                    </Text>
                </Space>
            </Modal>
        </div>
    )
}

export default NewVerifyOTPNewUser;