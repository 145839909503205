import React, { useEffect, useState } from "react";
import axios from "../../api";
import {
  Card,
  Button,
  Form,
  Input,
  Col,
  Row,
  Spin,
  Typography,
  Checkbox,
} from "antd";
import VerifyOTP from "../VerifyOTP/VerifyOTP";
import { useSearchParams } from "react-router-dom";
import "../ResetPassword/ResetPassword.css";
import TermsConditionModal from "../ResetPassword/TermsConditionModal";
import VerifyOTPNewUser from "../VerifyOTPNewUser/VerifyOTPNewUser";
import { EyeInvisibleIcon, EyeVisibleIcon, ExclamationSignIcon } from "../../assets";
import logo_se from "../../assets/images/logo-se.png";
import "./newResetPassword.css";
import NewVerifyOTPNewUser from "../VerifyOTPNewUser/NewVerifyOTPNewUser";
import NewVerifyOTP from "../VerifyOTP/NewVerifyOTP";

const { Text, Title } = Typography;

const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};

const NewResetPassword = () => {
  const TIMER_VALUE = 60;
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [resetEnableRetry, setResetEnableRetry] = useState(0)
  const [errorMsg, setErrorMsg] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [termsAvailable, setTermsAvailable] = useState(false);
  const [termsData, setTermsData] = useState(null);
  const [disabledClearBtn, setDisabledClearBtn] = useState(true);
  const [newUserInfo, setNewUserInfo] = useState({});

  const formValidationCheck = () => {
    // checking every form field have some value
    const formValuesExist = Object.values(form.getFieldsValue()).every(
      (value) => value
    );
    // checking every form field doesn't have any error
    const isValidFormFields = form
      .getFieldsError()
      .every((field) => field.errors.length === 0);
    // checking if terms and conditions are accepted when available
    const isTermsAccepted = termsAvailable ? termsAccepted : true;
    // checking if username & password field have some value
    const usernameValueExist = form.getFieldValue("username");
    const passwordValuesExist =
      form.getFieldValue("newPassword") ||
      form.getFieldValue("confirmNewPassword");

    if (searchParams.get("username")) {
      passwordValuesExist && setDisabledClearBtn(false);
      !passwordValuesExist && setDisabledClearBtn(true);
    } else {
      (usernameValueExist || passwordValuesExist) && setDisabledClearBtn(false);
      !(usernameValueExist || passwordValuesExist) && setDisabledClearBtn(true);
    }

    setIsFormValid(formValuesExist && isValidFormFields && isTermsAccepted);
  };

  const setIsReset = () => {
    if (searchParams.get("reset")) {
      setPasswordExpired(true);
    }
  };

  useEffect(() => {
    setIsReset();
  }, [searchParams.get("reset")]);


  useEffect(() => {
    formValidationCheck();
  }, [termsAvailable, termsAccepted]);

  const onFinish = async (values) => {
    setLoaderStatus(true);
    try {
      if (
        newUserInfo["isNewUser"] &&
        newUserInfo["isEmailVerified"] &&
        (newUserInfo["isPhoneVerified"] !== false)
      ) {
        setFormValues(values);
        setLoaderStatus(false);
        return;
      }
      // return
      // api request for generating otp
      let payload = {
        username: values.username,
      };
      if(newUserInfo["isNewUser"]){
        payload = {...payload, 
          isEmailVerified: newUserInfo["isEmailVerified"]
        }
        if(newUserInfo["isPhoneVerified"] === false)
          payload = {...payload, isPhoneVerified: false}
      }
      const response = await axios.post("/users/generateVerifyOtp", {
        ...payload,
      });
      if (response.status === 200 && response.data.otp_sent) {
        setFormValues(values);
        if (values.resendOtp) {
          setResetEnableRetry(resetEnableRetry + 1)
        }
      } else if (response.status === 200 && !response.data.otp_sent) {
        setErrorMsg(response.data.message);
      }
    } catch (error) {
      if (error && error.error) {
        setErrorMsg(error.error.message);
      } else if (error && error.message) {
        setErrorMsg(error.message);
      }
    }
    setLoaderStatus(false);
  };

  const passwordRegexValidator = async (value) => {
    if (!value) return;

    const isNonWhiteSpace = /^\S*$/;
    if (!isNonWhiteSpace.test(value)) {
      return Promise.reject(
        new Error("Password must not contain Whitespaces.")
      );
    }

    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    if (!isContainsUppercase.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Uppercase Character.")
      );
    }

    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsLowercase.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Lowercase Character.")
      );
    }

    const isContainsNumber = /^(?=.*[0-9]).*$/;
    if (!isContainsNumber.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Number.")
      );
    }

    const isContainsSymbol = /^(?=.*[#?!@$%^&*-]).*$/;
    if (!isContainsSymbol.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Special Character.")
      );
    }

    const isValidLength = /^.{8,15}$/;
    if (!isValidLength.test(value)) {
      return Promise.reject(
        new Error("Password must be 8-15 Characters Long.")
      );
    }

    return Promise.resolve();
  };

  const checkTermsAvailability = async (username) => {
    try {
      const usernameFieldError = form.getFieldError("username");
      if (usernameFieldError && usernameFieldError.length) return;
      // api request for fetching Terms & Conditions if available
      const response = await axios.post("/users/getTermsAndConditions", {
        username,
      });
      if (response.status === 200) {
        const {
          user,
          terms_and_conditions,
          is_accepted,
          UserType,
          isEmailVerified,
          isPhoneVerified,
          latest_terms_and_conditions,
        } = response.data;
        if (
          user &&
          (terms_and_conditions || latest_terms_and_conditions) &&
          !is_accepted
        ) {
          !termsAvailable && setTermsAvailable(true);
          setTermsData(terms_and_conditions || latest_terms_and_conditions);
        } else {
          if (
            user &&
            (terms_and_conditions || latest_terms_and_conditions) &&
            is_accepted
          ) {
            setTermsAccepted(true);
            setTermsData(terms_and_conditions || latest_terms_and_conditions);
          }
          termsAvailable && setTermsAvailable(false);
        }
        setNewUserInfo({
          isNewUser: UserType ? true : false,
          isEmailVerified,
          isPhoneVerified,
        });
      }
    } catch (error) {
      console.log(error);
      termsAvailable && setTermsAvailable(false);
    }
  };

  return (
    <div className={"SE-theme ResetPassword-wrapper eu-reset-password-wrapper"}>
      <Spin size="large" spinning={loaderStatus}>
        {!formValues && (
          <Card
            className={"SE-theme new-card-wrapper reset-pass-card"}
          >
            <div>
              <div className="transparent-green-cover"></div>
              <div className="green-cover"></div>
            </div>
           
            <Row className="reset-pass-form-container">
              <Col span={12}>
                <img src={logo_se} style={{height: "36lvh",marginTop:"-12.5lvh",marginLeft:"-4lvw"}} />
              </Col>
              <Col span={12}>
                <Row>
                  {
                  passwordExpired && <>
                      <Row>
                          <Text className="expired-text">
                                  Your old password has expired. Please set new password.
                          </Text>
                      </Row>
                  </>
                  }
                </Row>
                <Row>
                <Col span={12}> 
                <div className="new-card-wrapper-heading cs-title">Reset/Generate Password</div>
                  <Form
                    className={"SE-theme new-reset-pass-form"}
                    name="reset-password-form"
                    form={form}
                    colon={false}
                    size={"large"}
                    labelWrap
                    labelAlign={"left"}
                    layout="vertical"
                    onFieldsChange={() => formValidationCheck()}
                    onFinish={onFinish}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid Email!",
                        },
                      ]}
                    >
                      <Input
                          onBlur={(e) => checkTermsAvailability(e.target.value)}
                        placeholder="Email"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your new password!",
                        },
                        () => ({
                          async validator(_, value) {
                            await passwordRegexValidator(value);
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        iconRender={(visible) => <></>}
                        autocomplete="new-password"
                        placeholder="Password"
                        onPaste={(e) => e.preventDefault()}
                        onBlur={(e) =>
                          !termsAvailable &&
                          !termsAccepted &&
                          form.getFieldValue("username") &&
                          checkTermsAvailability(form.getFieldValue("username"))
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      label="Confirm Password"
                      name="confirmNewPassword"
                      dependencies={["newPassword"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please re-enter your new password!",
                        },
                        () => ({
                          async validator(_, value) {
                            await passwordRegexValidator(value);
                          },
                        }),
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Passwords do not match.")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        iconRender={(visible) => <></>}
                        onPaste={(e) => e.preventDefault()}
                        placeholder="Password"
                        onBlur={(e) =>
                          !termsAvailable &&
                          !termsAccepted &&
                          form.getFieldValue("username") &&
                          checkTermsAvailability(form.getFieldValue("username"))
                        }
                      />
                    </Form.Item>

                    {
                      termsAvailable && <>
                          <Form.Item {...tailLayout}>
                              <React.Fragment>
                                  <Text className="new-terms-and-conditions">
                                  <span onClick={() => setModalOpen(true)}>
                                      Read Privacy Policy here
                                  </span>
                                  </Text>
                                  <br />
                                  <Checkbox
                                      checked={termsAccepted}
                                      className={"se-theme-checkbox"}
                                      onClick={() => {
                                          if (termsAccepted) {
                                          setTermsAccepted(false);
                                          } else {
                                          !termsAccepted && setTermsError(true);
                                          }
                                      }}
                                  ></Checkbox>
                                  <Text
                                      className="new-terms-and-conditions"
                                      style={{ marginLeft: "7px" }}
                                  >
                                      {
                                          "By continuing you are agreeing to Schneider’s privacy policy"
                                      }
                                  </Text>
                                  <br />
                                  {termsError && (
                                      <Text
                                          className="new-terms-and-conditions"
                                          type="danger"
                                      >
                                          {
                                          "Please read the privacy policy before agreeing to privacy policy."
                                          }
                                      </Text>
                                  )}
                              </React.Fragment>
                          </Form.Item>
                      </>
                    }

                    {
                      errorMsg && <>
                          <Form.Item {...tailLayout} className="form-btns-group" style={{marginBottom: 0}}>
                              <Title level={5} type="danger" className="new-err-msg">
                                  {errorMsg}
                              </Title>
                          </Form.Item>
                      </>
                    }
                  </Form>
                </Col>
              <Col
                span={12}
                className="new-password-policy-box"
              >
                <fieldset className="new-password-policy">
                  <legend>Password Policy</legend>
                  <ul>
                    <div>
                        <span><ExclamationSignIcon /></span><span> At least one upper case character (ABCD...)</span>
                    </div>
                    <div>
                        <span><ExclamationSignIcon /></span><span> At least one lower case character (abcd...)</span>
                    </div>
                    <div>
                        <span><ExclamationSignIcon /></span><span> At least one numeric character (1234...)</span>
                    </div>
                    <div>
                        <span><ExclamationSignIcon /></span><span> At least one special character like (#?!@$%ˆ&*-)</span>
                    </div>
                    <div>
                        <span><ExclamationSignIcon /></span><span> Whitespaces are not allowed (pass word)</span>
                    </div>
                    <div>
                        <span><ExclamationSignIcon /></span><span> At least 8 characters long</span>
                    </div>
                    <div>
                        <span><ExclamationSignIcon /></span><span> At most 15 characters long</span>
                    </div>
                  </ul>
                </fieldset>
              </Col>
                </Row>
              </Col>
            </Row>
            <Row>
                <Col span={12}></Col>
                <Col span={12} style={{margin: '10px 0 20px 0'}}>
                    <Button
                        form="reset-password-form"
                        type="primary"
                        htmlType="submit"
                        className="new-gen-otp-btn"
                    >
                        Next
                    </Button>
                </Col>
            </Row>
          </Card>
        )}
        {!!formValues &&
          (!newUserInfo["isNewUser"] ||
            (newUserInfo["isNewUser"] && (!newUserInfo["isEmailVerified"] || (newUserInfo["isPhoneVerified"] === false)))) && (
            <NewVerifyOTP
              resetEnableRetry={resetEnableRetry}
              formValues={formValues}
              termsAccepted={termsAccepted}
              termsData={termsData}
              resendOtp={() => onFinish({ ...formValues, resendOtp: true })}
              isNewUser={newUserInfo["isNewUser"]}
              isEmailVerified={newUserInfo["isEmailVerified"]}
              isPhoneVerified={newUserInfo["isPhoneVerified"]}
            />
          )}
        {!!formValues &&
          newUserInfo["isNewUser"] &&
          newUserInfo["isEmailVerified"] &&
          (newUserInfo["isPhoneVerified"] !== false) && (
            <>
              <NewVerifyOTPNewUser
                formValues={formValues}
                newUserInfo={newUserInfo}
                termsAccepted={termsAccepted}
                termsData={termsData}
              />
            </>
        )}
      </Spin>
      {termsAvailable && (
        <TermsConditionModal
          termsData={termsData}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setTermsAccepted={setTermsAccepted}
          setTermsError={setTermsError}
          SETheme={"SE-theme"}
          username={form.getFieldValue("username")}
        />
      )}
    </div>
  );
};

export default NewResetPassword;
