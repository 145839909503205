import Header from "./components/Header/Header";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import NewResetPassword from "./components/newResetPassword.js/newResetPassword";
import MobileResetPassword from "./components/mobileResetPassword/mobileResetPassword";
import { BrowserRouter } from "react-router-dom";
import background from "./assets/images/newLoginBackground.png"
import { REACT_APP_EUROPE_DOMAIN_NAME_KEY } from "./lib/constants";
import "./App.css";
import Footer from "./components/appFooter/appFooter";
import { useEffect } from "react";
import HackTimer from "./scripts/hacktimer/HackTimer";

function App() {

  const isEUDashboardLogin = window.location.origin.toLowerCase().includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)

  useEffect(() => {
    if(isEUDashboardLogin){
      document.documentElement?.style?.setProperty('--primary-color', '#009530');
    }
  }, [isEUDashboardLogin])

  return (
    <div className="App" style={window.innerWidth < 500 ? {margin: 0} : 
      (isEUDashboardLogin ? {height: '100vh', 'background': 'url("' + background + '")', backgroundSize: 'cover', margin: 0} : {})}>
      <BrowserRouter>
        {
          window.innerWidth < 500 ? 
            <MobileResetPassword /> : 
            (isEUDashboardLogin ? <>
                <NewResetPassword />
                <Footer />
              </> : <>
                <Header />
                <ResetPassword />
              </>)
        }
      </BrowserRouter>
    </div>
  );
}

export default App;
