import React, { useEffect, useState } from "react";
import axios from "../../api";
import {
  Card,
  Button,
  Form,
  Input,
  Col,
  Row,
  Spin,
  Typography,
  Checkbox,
} from "antd";
import VerifyOTP from "../VerifyOTP/VerifyOTP";
import { useSearchParams } from "react-router-dom";
import "./ResetPassword.css";
import TermsConditionModal from "./TermsConditionModal";
import { REACT_APP_EUROPE_DOMAIN_NAME_KEY, REACT_APP_ORGANISATION_NAME } from "../../lib/constants";
import VerifyOTPNewUser from "../VerifyOTPNewUser/VerifyOTPNewUser";
import { EyeInvisibleIcon, EyeVisibleIcon } from "../../assets";

const { Text, Title } = Typography;

const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};

const ResetPassword = () => {
  const TIMER_VALUE = 60;
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [resetEnableRetry, setResetEnableRetry] = useState(0)
  const [errorMsg, setErrorMsg] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [termsAvailable, setTermsAvailable] = useState(false);
  const [termsData, setTermsData] = useState(null);
  const [disabledClearBtn, setDisabledClearBtn] = useState(true);
  const [newUserInfo, setNewUserInfo] = useState({})
  const [SETheme, setSETheme] = useState('')

  const formValidationCheck = () => {
    // checking every form field have some value
    const formValuesExist = Object.values(form.getFieldsValue()).every(
      (value) => value
    );
    // checking every form field doesn't have any error
    const isValidFormFields = form
      .getFieldsError()
      .every((field) => field.errors.length === 0);
    // checking if terms and conditions are accepted when available
    const isTermsAccepted = termsAvailable ? termsAccepted : true;
    // checking if username & password field have some value
    const usernameValueExist = form.getFieldValue("username");
    const passwordValuesExist =
      form.getFieldValue("newPassword") ||
      form.getFieldValue("confirmNewPassword");

    if (searchParams.get("username")) {
      passwordValuesExist && setDisabledClearBtn(false);
      !passwordValuesExist && setDisabledClearBtn(true);
    } else {
      (usernameValueExist || passwordValuesExist) && setDisabledClearBtn(false);
      !(usernameValueExist || passwordValuesExist) && setDisabledClearBtn(true);
    }

    setIsFormValid(formValuesExist && isValidFormFields && isTermsAccepted);
  };

  const setIsReset = () => {
    if (searchParams.get("reset")) {
      setPasswordExpired(true);
    }
  };

  useEffect(() => {
    setIsReset();
  }, [searchParams.get("reset")]);

  useEffect(() => {
    setSource();
  }, [searchParams.get("source")]);

  const setSource = () => {
    if (searchParams.get("source")) {
      setSETheme('SE-theme')
    }
  }

  useEffect(() => {
    formValidationCheck();
  }, [termsAvailable, termsAccepted]);

  const onFinish = async (values) => {
    setLoaderStatus(true);
    try {
      if(newUserInfo["isNewUser"] && newUserInfo["isEmailVerified"] && (newUserInfo["isPhoneVerified"] !== false)){
        setFormValues(values);
        setLoaderStatus(false);
        return
      }
      // return
      // api request for generating otp
      let payload = {
        username: values.username
      }
      if(newUserInfo["isNewUser"]){
        payload = {...payload, 
          isEmailVerified: newUserInfo["isEmailVerified"]
        }
        if(newUserInfo["isPhoneVerified"] === false)
          payload = {...payload, isPhoneVerified: false}
      }
      const response = await axios.post("/users/generateVerifyOtp", {...payload});
      if (response.status === 200 && response.data.otp_sent) {
        setFormValues(values);
        if (values.resendOtp) {
          setResetEnableRetry(resetEnableRetry + 1)
        }
      } else if (response.status === 200 && !response.data.otp_sent) {
        setErrorMsg(response.data.message);
      }
    } catch (error) {
      if (error && error.error) {
        setErrorMsg(error.error.message);
      } else if(error && error.message) {
        setErrorMsg(error.message);
      }
    }
    setLoaderStatus(false);
  };

  const onReset = () => {
    errorMsg && setErrorMsg("");
    form.resetFields();
    setIsReset();
    formValidationCheck();
  };

  const passwordRegexValidator = async (value) => {
    if (!value) return;

    const isNonWhiteSpace = /^\S*$/;
    if (!isNonWhiteSpace.test(value)) {
      return Promise.reject(
        new Error("Password must not contain Whitespaces.")
      );
    }

    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    if (!isContainsUppercase.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Uppercase Character.")
      );
    }

    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsLowercase.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Lowercase Character.")
      );
    }

    const isContainsNumber = /^(?=.*[0-9]).*$/;
    if (!isContainsNumber.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Number.")
      );
    }

    const isContainsSymbol = /^(?=.*[#?!@$%^&*-]).*$/;
    if (!isContainsSymbol.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Special Character.")
      );
    }

    const isValidLength = /^.{8,15}$/;
    if (!isValidLength.test(value)) {
      return Promise.reject(
        new Error("Password must be 8-15 Characters Long.")
      );
    }

    return Promise.resolve();
  };

  const checkTermsAvailability = async (username) => {
    try {
      const usernameFieldError = form.getFieldError("username");
      if (usernameFieldError && usernameFieldError.length) return;
      // api request for fetching Terms & Conditions if available
      const response = await axios.post('/users/getTermsAndConditions', { username });
      if (response.status === 200) {
        const { user, terms_and_conditions, is_accepted, UserType, isEmailVerified, isPhoneVerified, latest_terms_and_conditions } = response.data;
        if (user && (terms_and_conditions || latest_terms_and_conditions) && !is_accepted) {
          !termsAvailable && setTermsAvailable(true);
          setTermsData(terms_and_conditions || latest_terms_and_conditions);
        } else {
          if (user && (terms_and_conditions || latest_terms_and_conditions) && is_accepted) {
            setTermsAccepted(true);
            setTermsData(terms_and_conditions || latest_terms_and_conditions);
          }
          termsAvailable && setTermsAvailable(false);
        }
        setNewUserInfo({isNewUser: UserType ? true : false, isEmailVerified, isPhoneVerified })
      }
    } catch (error) {
      console.log(error);
      termsAvailable && setTermsAvailable(false);
    }
  };

  return (
    <div className={SETheme + ' ' + "ResetPassword-wrapper"}>
      <Spin size="large" spinning={loaderStatus}>
        {!formValues && (
          <Card
            title={passwordExpired ? "Update Password" : "Generate/Change Password"}
            className={SETheme + ' ' + "card-wrapper reset-pass-card"}
          >
            <Row>
              {passwordExpired && (
                <Text className="expired-text">
                  Your old password has expired. Please set new password.
                </Text>
              )}
              <Col span={24}>
                <Form
                  className={SETheme + ' ' + "reset-pass-form"}
                  name="reset-password-form"
                  form={form}
                  colon={false}
                  size={"large"}
                  labelWrap
                  labelAlign={"left"}
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 11,
                  }}
                  onFieldsChange={() => formValidationCheck()}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid username!",
                      },
                    ]}
                  >
                    <Input
                      onBlur={(e) => checkTermsAvailability(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your new password!",
                      },
                      () => ({
                        async validator(_, value) {
                          await passwordRegexValidator(value);
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      iconRender={(visible) => (visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />)}
                      autocomplete="new-password"
                      onPaste={(e) => e.preventDefault()}
                      onBlur={(e) =>
                        !termsAvailable &&
                        !termsAccepted &&
                        form.getFieldValue("username") &&
                        checkTermsAvailability(form.getFieldValue("username"))
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Re-enter New Password"
                    name="confirmNewPassword"
                    dependencies={["newPassword"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please re-enter your new password!",
                      },
                      () => ({
                        async validator(_, value) {
                          await passwordRegexValidator(value);
                        },
                      }),
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords do not match.")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      iconRender={(visible) => (visible ? <EyeVisibleIcon /> : <EyeInvisibleIcon />)}
                      onPaste={(e) => e.preventDefault()}
                      onBlur={(e) =>
                        !termsAvailable &&
                        !termsAccepted &&
                        form.getFieldValue("username") &&
                        checkTermsAvailability(form.getFieldValue("username"))
                      }
                    />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    {termsAvailable && (
                      <React.Fragment>
                        <Text className="terms-and-conditions">
                          Read{" "}
                          <span onClick={() => setModalOpen(true)}>
                            Privacy Policy
                          </span>{" "}
                          here
                        </Text>
                        <br />
                        <Checkbox
                          checked={termsAccepted}
                          className={SETheme && "se-theme-checkbox"}
                          onClick={() => {
                            if (termsAccepted) {
                              setTermsAccepted(false);
                            } else {
                              !termsAccepted && setTermsError(true);
                            }
                          }}
                        ></Checkbox>
                        <Text
                          className="terms-and-conditions"
                          style={{ marginLeft: "7px" }}
                        >
                          {`By continuing, you agree to ${
                            !window.location.origin
                              .toLowerCase()
                              .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                              ? REACT_APP_ORGANISATION_NAME.india
                              : REACT_APP_ORGANISATION_NAME.europe
                          }'s conditions of use.`}
                        </Text>
                        <br />
                        {termsError && (
                          <Text
                            className="terms-and-conditions"
                            type="danger"
                            style={{ marginLeft: "22px" }}
                          >
                            {
                              "Please read the privacy policy before agreeing to privacy policy."
                            }
                          </Text>
                        )}
                      </React.Fragment>
                    )}
                  </Form.Item>

                  <Form.Item {...tailLayout} className="form-btns-group">
                    {errorMsg && (
                      <Title level={5} type="danger" className="err-msg">
                        {errorMsg}
                      </Title>
                    )}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="form-btn gen-otp-btn"
                      disabled={!isFormValid}
                    >
                      Next
                    </Button>
                    <Button
                      htmlType="button"
                      onClick={onReset}
                      className="form-btn clear-form-btn"
                      disabled={disabledClearBtn}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col
                span={7}
                className="password-policy-box"
                style={{ top: passwordExpired ? "125px" : "" }}
              >
                <fieldset className="password-policy">
                  <legend>Password Policy</legend>
                  <ul>
                    <li> UPPER (CAPITAL) CHARACTER </li>
                    <li> lower (small) character </li>
                    <li> Number like 1234 </li>
                    <li> Special Character like #?!@$%^&*- </li>
                    <li> Whitespaces are not allowed </li>
                    <li> Minimum Length 8 </li>
                    <li> Maximum Length 15 </li>
                  </ul>
                </fieldset>
              </Col>
            </Row>
          </Card>
        )}
        {!!formValues && (!newUserInfo["isNewUser"] || (newUserInfo["isNewUser"] && (!newUserInfo["isEmailVerified"] || (newUserInfo["isPhoneVerified"] === false)))) && (
          <VerifyOTP
            formValues={formValues}
            termsAccepted={termsAccepted}
            termsData={termsData}
            resetEnableRetry={resetEnableRetry}
            resendOtp={() => onFinish({ ...formValues, resendOtp: true })}
            isNewUser={newUserInfo["isNewUser"]}
            isEmailVerified={newUserInfo["isEmailVerified"]}
            isPhoneVerified={newUserInfo["isPhoneVerified"]}
            SETheme={SETheme}
          />
        )}
        {
          !!formValues && (newUserInfo["isNewUser"] && newUserInfo["isEmailVerified"] && (newUserInfo["isPhoneVerified"] !== false)) && <>
              <VerifyOTPNewUser 
                formValues={formValues}
                newUserInfo={newUserInfo}
                termsAccepted={termsAccepted}
                termsData={termsData}
                SETheme={SETheme}
              />
            </>
        }
      </Spin>
      {termsAvailable && (
        <TermsConditionModal
          termsData={termsData}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setTermsAccepted={setTermsAccepted}
          setTermsError={setTermsError}
          SETheme={SETheme}
          username={form.getFieldValue("username")}
        />
      )}
    </div>
  );
};

export default ResetPassword;
