import React, { useEffect, useState } from "react";
import "./mobileResetPassword.css";
import { Button, Form, Input, Checkbox, Typography, Spin } from "antd";
import {
  REACT_APP_EUROPE_DOMAIN_NAME_KEY,
  REACT_APP_ORGANISATION_NAME,
} from "../../lib/constants";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { InfoIcon } from "../../assets";
import TermsConditionModal from "../ResetPassword/TermsConditionModal";
import VerifyOTPUserCMA from "../verifyOTPUserCMA/verifyOTPUserCMA";
import MobileVerifyOTP from "../mobileVerifyOTP/mobileVerifyOTP";

const { Text, Title } = Typography;

const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};

const MobileResetPassword = () => {

  const [termsAvailable, setTermsAvailable] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [termsData, setTermsData] = useState(null);
  const [newUserInfo, setNewUserInfo] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [enableRetry, setEnableRetry] = useState(true);
  const [enableRetryPhone, setEnableRetryPhone] = useState(true);
  const [timerCount, setTimerCount] = useState(60);
  const [timerCountPhone, setTimerCountPhone] = useState(60);

  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    formValidationCheck();
  }, [termsAvailable, termsAccepted]);

  useEffect(() => {
    !enableRetry && formValues && resendOtpInterval();
  }, [timerCount, enableRetry]);

  useEffect(() => {
    !enableRetryPhone && formValues && resendOtpIntervalPhone();
  }, [timerCountPhone, enableRetryPhone]);

  const resendOtpInterval = () => {
    if (timerCount > 0) {
      setTimeout(() => {
        setTimerCount((timerCount) => timerCount - 1);
      }, 1000);
    } else {
      setEnableRetry(true);
      setTimerCount(60);
    }
  };

  const resendOtpIntervalPhone = () => {
    if (timerCountPhone > 0) {
      setTimeout(() => {
        setTimerCountPhone((timerCountPhone) => timerCountPhone - 1);
      }, 1000);
    } else {
      setEnableRetryPhone(true);
      setTimerCountPhone(60);
    }
  };

  const formValidationCheck = () => {
    // checking every form field have some value
    const formValuesExist = Object.values(form.getFieldsValue()).every(
      (value) => value
    );
    // checking every form field doesn't have any error
    const isValidFormFields = form
      .getFieldsError()
      .every((field) => field.errors.length === 0);
    // checking if terms and conditions are accepted when available
    const isTermsAccepted = termsAvailable ? termsAccepted : true;
    // checking if username & password field have some value
    const usernameValueExist = form.getFieldValue("username");
    const passwordValuesExist =
      form.getFieldValue("newPassword") ||
      form.getFieldValue("confirmNewPassword");

    setIsFormValid(formValuesExist && isValidFormFields && isTermsAccepted);
  };

  const checkTermsAvailability = async (username) => {
    try {
      const usernameFieldError = form.getFieldError("username");
      if (usernameFieldError && usernameFieldError.length) return;
      // api request for fetching Terms & Conditions if available
      const response = await axios.post("/users/getTermsAndConditions", {
        username,
      });
      if (response.status === 200) {
        const {
          user,
          terms_and_conditions,
          is_accepted,
          UserType,
          isEmailVerified,
          isPhoneVerified,
          latest_terms_and_conditions,
        } = response.data;
        if (
          user &&
          (terms_and_conditions || latest_terms_and_conditions) &&
          !is_accepted
        ) {
          !termsAvailable && setTermsAvailable(true);
          setTermsData(terms_and_conditions || latest_terms_and_conditions);
        } else {
          if (
            user &&
            (terms_and_conditions || latest_terms_and_conditions) &&
            is_accepted
          ) {
            setTermsAccepted(true);
            setTermsData(terms_and_conditions || latest_terms_and_conditions);
          }
          termsAvailable && setTermsAvailable(false);
        }
        setNewUserInfo({
          isNewUser: UserType ? true : false,
          isEmailVerified,
          isPhoneVerified,
        });
      }
    } catch (error) {
      console.log(error);
      termsAvailable && setTermsAvailable(false);
    }
  };

  const passwordRegexValidator = async (value) => {
    if (!value) return;

    const isNonWhiteSpace = /^\S*$/;
    if (!isNonWhiteSpace.test(value)) {
      return Promise.reject(
        new Error("Password must not contain Whitespaces.")
      );
    }

    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    if (!isContainsUppercase.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Uppercase Character.")
      );
    }

    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsLowercase.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Lowercase Character.")
      );
    }

    const isContainsNumber = /^(?=.*[0-9]).*$/;
    if (!isContainsNumber.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Number.")
      );
    }

    const isContainsSymbol = /^(?=.*[#?!@$%^&*-]).*$/;
    if (!isContainsSymbol.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one Special Character.")
      );
    }

    const isValidLength = /^.{8,15}$/;
    if (!isValidLength.test(value)) {
      return Promise.reject(
        new Error("Password must be 8-15 Characters Long.")
      );
    }

    return Promise.resolve();
  };

  const onFinish = async (values) => {
    setLoaderStatus(true);
    try {
      if(newUserInfo["isNewUser"] && newUserInfo["isEmailVerified"] && newUserInfo["isPhoneVerified"]){
        setFormValues(values);
        setLoaderStatus(false);
        return
      }
      // return
      // api request for generating otp
      let payload = {
        username: values.username
      }
      if(newUserInfo["isNewUser"]){
        payload = {...payload, 
          isEmailVerified: newUserInfo["isEmailVerified"],
          isPhoneVerified: newUserInfo["isPhoneVerified"]
        }
      }
      const response = await axios.post("/users/generateVerifyOtp", {...payload});
      if (response.status === 200 && response.data.otp_sent) {
        setFormValues(values);
        if (values.resendOtp) {
          setEnableRetry(false);
          setEnableRetryPhone(false)
        }
      } else if (response.status === 200 && !response.data.otp_sent) {
        setErrorMsg(response.data.message);
      }
    } catch (error) {
      if (error && error.error) {
        setErrorMsg(error.error.message);
      } else if(error && error.message) {
        setErrorMsg(error.message);
      }
    }
    setLoaderStatus(false);
  };

  return (
    <div>
      <Spin size="medium" spinning={loaderStatus}>
      {!formValues && <><div className="background-screen-reset-password">RESET/GENERATE PASSWORD</div>
      <div className="details-input-container">
        <Form
          className={"reset-pass-form reset-pass-form-cma"}
          name="reset-password-form"
          form={form}
          colon={false}
          size={"large"}
          labelWrap
          labelAlign={"left"}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 11,
          }}
          onFieldsChange={() => formValidationCheck()}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            // label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
              {
                type: "email",
                message: "Please enter a valid username!",
              },
            ]}
            style={{marginBottom: '40px'}}
          >
            <div className="float-label">
              <Input onBlur={(e) => checkTermsAvailability(e.target.value)} placeholder="Enter Your Username"/>
              <label className={"label as-label"}>{"Username"}</label>
            </div>
          </Form.Item>

          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please enter your new password!",
              },
              () => ({
                async validator(_, value) {
                  await passwordRegexValidator(value);
                },
              }),
            ]}
            hasFeedback
            style={{marginBottom: '40px'}}
          >
            <div className="float-label">
                <Input.Password
                    iconRender={(visible) => (visible ? <></> : <></>)}
                    autocomplete="new-password"
                    onPaste={(e) => e.preventDefault()}
                    onBlur={(e) =>
                        !termsAvailable &&
                        !termsAccepted &&
                        form.getFieldValue("username") &&
                        checkTermsAvailability(form.getFieldValue("username"))
                    }
                    placeholder="Enter Your Password"
                />
                <label className={"label as-label"}>{"Password"}</label>
            </div>
          </Form.Item>

          <Form.Item
            name="confirmNewPassword"
            dependencies={["newPassword"]}
            hasFeedback
            style={{marginBottom: '40px'}}
            rules={[
              {
                required: true,
                message: "Please re-enter your new password!",
              },
              () => ({
                async validator(_, value) {
                  await passwordRegexValidator(value);
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match."));
                },
              }),
            ]}
          >
            <div className="float-label">
                <Input.Password
                    iconRender={(visible) => (visible ? <></> : <></>)}
                    onPaste={(e) => e.preventDefault()}
                    onBlur={(e) =>
                        !termsAvailable &&
                        !termsAccepted &&
                        form.getFieldValue("username") &&
                        checkTermsAvailability(form.getFieldValue("username"))
                    }
                    placeholder="Re-enter Password"
                />
                <label className={"label as-label"}>{"Confirm Password"}</label>
            </div>
          </Form.Item>

            <div className="password-policy-cma">
                <div>Password Policy</div>
                <ul>
                    <div><InfoIcon /> At least one upper case character (ABCD...)</div>
                    <div><InfoIcon /> At least one lower case character (abcd...)</div>
                    <div><InfoIcon /> At least one numeric character (1234...)</div>
                    <div><InfoIcon /> At least one special character like (!@#$%^&*-)</div>
                    <div><InfoIcon /> Whitespaces are not allowed (pass word)</div>
                    <div><InfoIcon /> At least 8 characters long</div>
                    <div><InfoIcon /> At most 15 characters long</div>
                </ul>
            </div>

          {termsAvailable &&
            <Form.Item {...tailLayout}>
                <React.Fragment>
                    <Text className="terms-and-conditions-cma">
                        <span onClick={() => setModalOpen(true)}>
                            Read Privacy Policy here
                        </span>
                    </Text>
                    <Checkbox
                        checked={termsAccepted}
                        className={"theme-checkbox"}
                        onClick={() => {
                            if (termsAccepted) {
                            setTermsAccepted(false);
                            } else {
                            !termsAccepted && setTermsError(true);
                            }
                        }}
                        style={{lineHeight: '18px', marginTop: '5px'}}
                    >
                        {`By continuing, you agree to ${
                            !window.location.origin
                            .toLowerCase()
                            .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                            ? REACT_APP_ORGANISATION_NAME.india
                            : REACT_APP_ORGANISATION_NAME.europe
                        }'s conditions of use.`}
                    </Checkbox>
                    {termsError && (
                        <Text
                            className="terms-and-conditions"
                            type="danger"
                            style={{ margin: "5px 0 0 22px", display: 'block', lineHeight: '18px' }}
                        >
                            {
                            "Please read the privacy policy before agreeing to privacy policy."
                            }
                        </Text>
                    )}
                </React.Fragment>
            </Form.Item>
          }

            {errorMsg && (
              <Title level={5} type="danger" className="err-msg" style={{textAlign: 'center'}}>
                {errorMsg}
              </Title>
            )}
          <Form.Item {...tailLayout} className="form-btns-group">
            <Button
              type="primary"
              htmlType="submit"
              className={`form-btn-save-cma ${!isFormValid && ' disable-btn'}`}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </div></>}
      {!!formValues && ((!newUserInfo["isNewUser"] && !newUserInfo["isEmailVerified"]) || (newUserInfo["isNewUser"] && (!newUserInfo["isEmailVerified"] || !newUserInfo["isPhoneVerified"]))) && (
        <MobileVerifyOTP
            enableRetry={enableRetry}
            enableRetryPhone={enableRetryPhone}
            timerCount={timerCount}
            timerCountPhone={timerCountPhone}
            formValues={formValues}
            termsAccepted={termsAccepted}
            termsData={termsData}
            setEnableRetry={setEnableRetry}
            setEnableRetryPhone={setEnableRetryPhone}
            resendOtp={() => onFinish({ ...formValues, resendOtp: true })}
            isNewUser={newUserInfo["isNewUser"]}
            isEmailVerified={newUserInfo["isEmailVerified"]}
            isPhoneVerified={newUserInfo["isPhoneVerified"]}
        />
      )}
      {
        !!formValues && ((newUserInfo["isNewUser"] && newUserInfo["isEmailVerified"] && newUserInfo["isPhoneVerified"]) || 
        (!newUserInfo["isNewUser"] && newUserInfo["isEmailVerified"])) && <>
            <VerifyOTPUserCMA
                formValues={formValues}
                newUserInfo={newUserInfo}
                termsAccepted={termsAccepted}
                termsData={termsData}
            />
        </>
      }
      </Spin>
      {termsAvailable && (
        <TermsConditionModal
          termsData={termsData}
          isMobileView={true}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setTermsAccepted={setTermsAccepted}
          setTermsError={setTermsError}
          username={form.getFieldValue("username")}
        />
      )}
    </div>
  );
};

export default MobileResetPassword;
